/**
 * Site header
 */
.site-header {
    //border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 59px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
            font-style: bold;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */

div.footnotes {
    border-top: 1px solid $grey-color-light;
    margin-top: 40px;
    padding-top: 10px;
    font-size: $small-font-size;
}

.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    text-align: right;
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

.post-header {

    h1 {
        margin-bottom: 0px;
    }

    margin-bottom: 30px;
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

div.copyright-note {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $grey-color-light;

    color: $grey-color-dark;
    font-size: $small-font-size;

    img {
        margin: 0px;
        padding-right: 10px;
        float: left;
    }
}


div.post-comment-note {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid $grey-color-light;

    color: $grey-color-dark;
    font-size: $small-font-size;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */

#event-block {
    width: 49%;
    float: left;
    word-wrap: break-word;
    margin-right: 10px;
    margin-top: 40px;
}

#post-block {
    width: 49%;
    float: right;
    margin-top: 40px;
}

.event-list {

    article {
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 11pt;
        font-weight: bold;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .event-time {
        font-size: $smaller-font-size;
        color: $grey-color;
        display: block;
    }

    .event-loc {
        float: right;
        font-size: $small-font-size;
        color: $grey-color;
    }

    .event-loc:before {
        content: '@';
    }

    .event-desc {
        font-style: italic;
        font-size: $small-font-size;
        display: block;
    }
}

.post-list {

    article {
        background: #eee;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 11pt;
        font-weight: bold;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    a {
        color: black;
        text-decoration: none;

        :hover {
            background: #dde;
        }
    }

    .post-meta {
        font-size: $smaller-font-size;
        color: $grey-color;
    }

    .post-cat {
        font-size: 8pt;
        text-align: right;
        color: $grey-color;
    }

    p {
        margin-bottom: 0px;
    }

}

