div.fixed-space-status {
  background-image: url(/img/space-down-fixed.png);
  width: 50px;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

a.fixed-space-status {
  font-size: 0.8em;
  display: table;
  margin: 0 auto;
}

a.fixed-space-status:hover {
  text-decoration: none !important;
}

